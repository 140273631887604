<template>
  <section class="project-house-steps">
    <section class="hero-small">
      <div>
        <h1>Construire sa maison : les étapes-clés</h1>
      </div>
    </section>

    <div class="grid-container">
      <div class="intro">
        <h6>Devenir propriétaire de sa maison individuelle, se lancer dans un projet de construction, voilà une aventure passionnante !</h6>
        <p>Cette expérience, notamment pour le primo-accédant, est un parcours initiatique dont les jalons sont nombreux et techniques et qui réclame volonté et sérénité. C’est tout à fait normal, la construction d’une maison est un achat complexe, au cycle de vie long et qui représente pour l’acquéreur l’achat d’une vie.  Un projet qui mérite donc le choix et l’accompagnement d’un professionnel digne de confiance et dont l’engagement est total.</p>
      </div>

      <div class="cards-container">
        <div class="card-container">
          <card-type-img
            :pastille="{text: 1, theme: 'blue'}"
            title="L’élaboration du projet"
            class="card"
          >
            <template v-slot:content>
              <blockquote>« Un projet de maison, oui mais celle qui vous ressemble, la vôtre, pour un vrai projet de vie. »</blockquote>
              <p>Définition du budget, montage du meilleur plan de financement car le plus adapté à votre profil, choix du terrain, définition et conception de votre maison (nombre de pièces, superficie, plain-pied ou à étage, agencement et distribution des pièces… ), attentes en termes de style, exposition et orientation, équipements et options… c’est tout un univers de choix enthousiasmants où il n’y a que des occasions de se faire plaisir.</p>
            </template>

            <template v-slot:img>
              <img src="@/assets/img/pages/etape_projet.png" alt="Étape projet" />
            </template>
          </card-type-img>

          <div class="line-container">
            <span/>
            <span/>
          </div>
        </div>

        <div class="line-between-card right">
          <span/>
        </div>

        <div class="card-container">
          <div class="line-container">
            <span/>
            <span/>
          </div>

          <card-type-img
            :pastille="{text: 2, theme: 'red'}"
            title="La phase administrative"
            class="card"
          >
            <template v-slot:content>
              <blockquote>« Avec Maisons Alysia vous êtes bien entouré. »</blockquote>
              <p>Votre constructeur Maisons Alysia vous accompagne tout au long du déroulement de votre projet de construction étape par étape. A votre disposition, une et une seule personne au sein de l’agence : votre Chargée de Projet qui assure la prise en charge de l’ensemble des démarches administratives et juridiques :</p>
              <ul>
                <li>La recherche terrain et les modalités d’exploitation</li>
                <li>Le montage du financement et l’obtention du prêt bancaire</li>
                <li>L’élaboration du chiffrage et de la notice descriptive</li>
                <li>La constitution et le suivi d’instruction du dossier de Permis de Construire</li>
                <li>La signature du contrat de Construction de Maison Individuelle issu de la loi du 19 décembre 1990, obligatoire pour un constructeur. <router-link :to="{ name: 'construction-contract' }">En savoir + sur le CCMI.</router-link></li>
                <li>La réunion technique de mise au point : matériaux, choix définitif des équipements et des options…</li>
              </ul>
            </template>

            <template v-slot:img>
              <img src="@/assets/img/pages/etape_administratif.png" alt="Étape administratif" />
            </template>
          </card-type-img>
        </div>

        <div class="line-between-card left">
          <span/>
        </div>

        <div class="card-container">
          <card-type-img
            :pastille="{text: 3, theme: 'yellow'}"
            title="Le chantier de construction"
            class="card"
          >
            <template v-slot:content>
              <blockquote>« C’est ce que préfère nos clients, savoir sur qui compter. »</blockquote>
              <p>C’est parti !<br>Tout de suite, vous appréciez encore la différence chez Maisons Alysia : c’est votre constructeur (le gérant de l’agence), que vous avez en face de vous dans le rôle Ô combien stratégique du conducteur de travaux. Il est le garant de la mise en œuvre, du suivi, du planning et du respect des délais de votre chantier de construction. Véritable coordinateur des équipes de production, un réseau d’artisans experts, il assure la bonne conduite et la participation des différentes corps d’états et la qualité de  l’exécution de chaque chantier, du terrassement aux finitions en passant par la couverture et les menuiseries (et surtout, dans l’ordre).</p>
              <p>Il est le référent unique qui vous accompagne dans cette étape : reporting, réponses à vos questions et interrogations sur le déroulement de votre construction.</p>
            </template>

            <template v-slot:img>
              <img src="@/assets/img/pages/etape_construction.png" alt="Étape construction" />
            </template>
          </card-type-img>

          <div class="line-container">
            <span/>
            <span/>
          </div>
        </div>

        <div class="line-between-card right">
          <span/>
        </div>

        <div class="card-container">
          <div class="line-container"/>

          <card-type-img
            :pastille="{text: 4, theme: 'green'}"
            title="La livraison de votre Maison"
            class="card"
          >
            <template v-slot:content class="content">
              <blockquote>« L’engagement et la responsabilité de votre constructeur s’éprouvent à chaque instant, même au dernier ! »</blockquote>
              <p>Enfin, le moment tant attendu, celui de la livraison de votre résidence principale et de votre futur cadre de vie ! Cet aboutissement donne lieu à plusieurs formalités et évènements :</p>
              <ul>
                <li>La remise des clés</li>
                <li>Le procès-verbal de livraison co-signé du constructeur et de l’acquéreur : y sont consignées les éventuelles réserves en cas de constatation d’anomalies. Cette étape déclenche le démarrage des garanties Dommages-Ouvrage, Décennale et de Parfait Achèvement…</li>
                <li>La visite et la «prise en main» de la maison donnent lieu à une inspection permettant à l’acquéreur de vérifier la conformité de son bien en relation avec le plan et la notice descriptive dont il dispose.</li>
              </ul>
            </template>

            <template v-slot:img class="img">
              <img src="@/assets/img/pages/etape_livraison.png" alt="Étape livraison" />
            </template>
          </card-type-img>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'project-house-steps',

  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.project-house-steps
  padding: 0 0 4rem
  background: $medium-bg

  .hero-small
    @include hero-small
    background-image: linear-gradient(180deg, rgba(10, 10, 10, .6) 0%, rgba(32, 32, 32, .2) 100%), url(~@/assets/img/pages/house-steps.png)

  .intro
    margin: 6rem auto 5rem
    padding: 0 1rem
    max-width: 925px
    text-align: center
    p
      margin-top: 30px

  .card-type-img
    margin: 3rem auto
    p ~ p
      margin-top: 1.5rem

  .cards-container

    .card-container
      display: flex
      justify-content: space-between

      .card
        margin: 0
        width: 90%
        position: relative

        .content
          flex: 1
          width: auto

        .img
          width: 280px
          min-width: auto
          margin-right: 50px
          position: initial

      &:not(:first-of-type)
        .card
          &::before
            content: ''
            position: absolute
            top: -40px
            left: 50%
            width: 4px
            height: 40px
            border-right: 4px dotted $primary

          &::after
            content: ''
            position: absolute
            top: -40px
            left: calc(50% + 4px)
            width: 4px
            height: 40px
            background: linear-gradient(180deg, rgba($background, .5) 0%, rgba($background, .8) 100%)

      .line-container
        flex: 1
        position: relative

        span
          position: absolute

        span
          &:first-child
            top: calc(50% - 2px)
            width: 50%
            border-top: 4px dotted $primary

            &::before
              content: ''
              position: absolute
              top: -9px
              width: 7px
              height: 14px
              background-color: $primary
              border-top-right-radius: 7px
              border-bottom-right-radius: 7px

        span
          &:last-child
            top: calc(50% - 2px)
            right: 50%
            height: calc(50% + 40px)
            border-right: 4px dotted $primary

          &::after
            content: ''
            position: absolute
            top: 0
            width: 4px
            height: 100%
            background: linear-gradient(180deg, rgba($background, 0) 0%, rgba($background, .5) 100%)

        &:nth-child(odd)
          transform: scaleX(-1)

      .card
        &:nth-child(odd)
          &::before
            width: 0

          &::after
            left: 50%

    .line-between-card
      height: 80px
      position: relative

      span
        position: absolute
        top: 50%
        width: calc(50% - 10% - 4px)
        height: 4px
        border-top: 4px dotted $primary
        opacity: .5

    .line-between-card.right
      span
        right: 5%

    .line-between-card.left
      span
        left: 5%

  @media (max-width: 768px)
    .intro
      margin: 4rem auto

    figure
      margin: 0 0 30px 0!important

      img
        width: 250px!important
        max-width: 50vw
        height: 250px

    h2
      display: flex
      align-items: center

      span
        margin-right: 12px!important

    .cards-container
      .card-container
        width: 90vw
        display: block
        margin: auto
        .card
          width: unset
          max-width: 100%
        &:not(:first-of-type)
          .card

            &::before, &::after
              top: -50px
              height: 50px

            &::before
              width: 4px
              left: calc(50% - 8px)

            &::after
              left: calc(50% - 4px)
              background: linear-gradient(180deg, rgba($background, 0) 0%, rgba($background, .8) 100%)

      .line-between-card
        height: 50px

        span
          display: none

    .line-container
      display: none
</style>
